.header-root {
    height: 75px;
    border-bottom: 1px solid #EFEFEF;
    margin-bottom: 20px;
    position: relative;
}
.header-logo {
    width: 100%;
    height: 100%;
    background: url('../../assets/HeaderLogo.svg');
    position: relative;
    background-repeat: no-repeat;
    margin-left: 24px;
    margin-top: 17px;
    margin-bottom: 18px;
  }
.bracket-icon {
    width: 100%;
    height: 100%;
    background: url('../../assets/AngleBracketIcon.svg');
    position: relative;
    background-repeat: no-repeat;
    margin-top: 17px;
    margin-bottom: 18px;
    margin-right: 25px;
    color: #353535;
    font-size: 16px;
    padding-top: 0px;
}
.bulb-icon {
    width: 100%;
    height: 100%;
    background: url('../../assets/BulbIcon.svg');
    position: relative;
    background-repeat: no-repeat;
    margin-top: 17px;
    margin-bottom: 18px;
    margin-right: 25px;
    color: #353535;
    font-size: 16px;
    padding-top: 3px;
}
.support-icon {
    width: 100%;
    height: 100%;
    background: url('../../assets/SupportIcon.svg');
    position: relative;
    background-repeat: no-repeat;
    margin-top: 17px;
    margin-bottom: 18px;
    margin-right: 25px;
    color: #353535;
    font-size: 16px;
    padding-top: 2px;
}
.docs-icon {
    width: 100%;
    height: 100%;
    background: url('../../assets/DocsIcon.svg');
    position: relative;
    background-repeat: no-repeat;
    margin-top: 17px;
    margin-bottom: 18px;
    margin-right: 25px;
    color: #353535;
    font-size: 16px;
    padding-top: 2px;
}

.bracket-icon:hover {
    text-decoration: none;
  }
.bulb-icon:hover {
    text-decoration: none;
  }
.support-icon:hover {
    text-decoration: none;
}
.docs-icon:hover {
    text-decoration: none;
}