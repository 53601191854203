.footer {
    background: rgb(0, 36, 61);
    color: #fff;
    width: 100%;
    padding: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 150px;
}
.links {
    display: flex;
    gap: 24px;
    cursor: pointer;
}
.link {
    color: #fff;
}
.logo {
    margin-bottom: 12px;
}