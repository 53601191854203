.label {
    color: #5B7D94;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}
.heading_team {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 40px;
    color: #000000;
    margin-top: 8px;
}
.description {
    width: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #353535;
    margin-top: 15px;
}
.table {
    margin-top: 2rem;
    width: 70%;
}
.choose-role-button {
    background: none;
    border: 1px solid #1414;
    border-radius: 5px;
}
