.container-style {
    background: rgba(191, 215, 221, 0.2);
    height: 50%;
    border-radius: 8px;
    padding: 35px;
}
.team-card-style {
    border: 1px solid #D2DFE8;
    padding: 10px;
    box-shadow: none !important;
    border-radius: 4px;
    width: 280px;
    height: 140px;
    margin-bottom: 35px;
}
.description-style {
    color: #878787;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
}
.team-name-footer-style {
    color: #878787;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    margin-top: 95px;
}