.shared-breadcrumb-m {
    margin-left: 40px !important;
    color: #353535;
}
.breadcrumbs-style {
    display: flex;
    align-items: center;
    color: #353535;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}